<template>
  <CancellationPolicy
    @checkIsUnsaved="checkForUnsavedSections"
    @checkIsValid="setSectionsValidity"
    @updateData="setSectionsData" />
  <UnsavedChangesModal
    :isOpen="isModalOpen"
    :isInvalidSectionsPresent="isInvalidSectionsPresent"
    @toggleModal="cancelModal"
    @continue="moveToNextRoute"
    @saveAndContinue="save">
    <template v-slot:title>
      <span class="text-gray-800">
        You have unsaved changes.
      </span>
      <ul class="hidden text-sm text-water font-normal">
        <li v-for="section in unsavedSections" :key="section">
          {{ section.title }}
        </li>
      </ul>
    </template>
    <template v-slot:content>
      <p class="leading-6">You are about to exit the page. <br />All unsaved changes will be lost.</p>
    </template>
    <template v-slot:buttonName>
      Discard
    </template>
  </UnsavedChangesModal>
</template>
<script>
import { ref } from "vue";
import { useRoute } from "vue-router";
import CancellationPolicy from "@/components/views/Listing/PoliciesAndRules/CancellationPolicy";
import UnsavedChangesModal from "@/components/UI/Popups/Modal/AppUnsavedChangesModal";
import { useUnsavedModal } from "@/use/unsavedModal";

export default {
  name: "PoliciesAndRules",
  components: {
    CancellationPolicy,
    UnsavedChangesModal
  },
  setup() {
    const route = useRoute();
    const isEditProductMode = !!route.params.id;

    const detailsSectionData = ref({
      cancellationPolicy: null
    });

    const setSectionsData = (section, value) => {
      detailsSectionData.value[section] = value;
    };

    const {
      checkForUnsavedSections,
      setSectionsValidity,
      saveUnsavedSections,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal
    } = useUnsavedModal(['addons']);

    const save = () => {
      const preparedData = {
        cancellation_policy: detailsSectionData.value.cancellationPolicy
      };

      saveUnsavedSections(preparedData, isEditProductMode);
    }

    return {
      checkForUnsavedSections,
      setSectionsValidity,
      isInvalidSectionsPresent,
      unsavedSections,
      isModalOpen,
      moveToNextRoute,
      cancelModal,
      setSectionsData,
      save
    }
  }
}
</script>
