<template>
  <SectionWrapper
    v-if="!updateLoading && !loading"
    id="cancellation-policy"
    title="Policies"
    description="Choose your preferred policies below. <a class='relative cursor-pointer rounded-md font-medium text-highlight-600 hover:text-highlight-500' target='_blank' href='https://owayy.slab.com/posts/how-to-adjust-your-cancellation-policy-mfp9ic4b'>More Information</a>"
    :isValidSection="isTouched"
    :show-skip-button="false"
    @saveSection="saveCancellation">
    <div>
      <div class="flex items-center justify-between mb-6 pt-3 border-t">
        <h2 class="block text-lg font-medium text-gray-700">Cancellation Policy</h2>
        <div class="flex items-center space-x-2">
          <div>
            <button class="block text-xs font-medium text-gray-700 hover:bg-gray-50 px-2 py-1 rounded-md" @click="toggleEditPolicy">{{ isPolicyEditable ? 'OK' : 'Edit' }}</button>
          </div>
        </div>
      </div>
      <div v-if="!isPolicyEditable">
        <div class="bg-gray-100 px-6 py-4 rounded-lg text-sm">
          <p class="font-medium text-gray-900">{{ selected.title }}</p>
          <p class="text-gray-500">{{ selected.desc }}</p>
        </div>
      </div>
      <div v-else>
        <RadioGroup v-model="selected">
          <RadioGroupLabel class="sr-only">
            Cancellation Policy
          </RadioGroupLabel>
          <div class="space-y-4">
            <RadioGroupOption v-for="policy in policies" :key="policy.name" as="template" :value="policy" @click="setTouch" v-slot="{ checked, active }">
              <div :class="[checked ? 'border-transparent' : 'border-gray-300', active ? 'ring-2 ring-blue-500' : '',
                            'relative block bg-white border rounded-lg shadow-sm px-6 py-4 cursor-pointer ' +
                              'sm:flex sm:justify-between focus:outline-none']">
                <div class="flex items-center">
                  <div class="text-sm">
                    <RadioGroupLabel as="p" class="font-medium text-gray-900">
                      {{ policy.title }}
                    </RadioGroupLabel>
                    <RadioGroupDescription as="div" class="text-gray-500">
                      <p class="sm:inline ">{{ policy.desc }}</p>
                    </RadioGroupDescription>
                  </div>
                </div>
                <div :class="[!checked ? 'invisible' : 'visible', 'absolute right-3 top-4']">
                  <CheckCircleIcon class="h-5 w-5 text-blue-600" aria-hidden="true" />
                </div>
                <div :class="[active ? 'border' : 'border-2', checked ? 'border-blue-500' : 'border-transparent', 'absolute -inset-px rounded-lg pointer-events-none']" aria-hidden="true" />
              </div>
            </RadioGroupOption>
          </div>
        </RadioGroup>
      </div>
    </div>
  </SectionWrapper>
  <div v-else class="loading-state flex justify-center py-20 bg-gray-800 bg-opacity-5 rounded-lg">
    <LoadingSpinner outer-class="h-10 h-10 text-water" />
  </div>
</template>

<script>
import { computed, ref, watch } from "vue";
import { CheckCircleIcon } from '@heroicons/vue/solid';
import { useQuery, useMutation } from '@vue/apollo-composable'
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import SectionWrapper from "@/components/UI/SectionWrappers/AppSectionWrapper";
import LoadingSpinner from "@/components/UI/Indication/LoadingSpinner";
import { GET_CANCELLATION_POLICY } from "@/graphql/products/policy/policy";
import { UPDATE_PRODUCT } from "@/graphql/products/mutations";

export default {
  name: "CancellationPolicy",
  components: {
    SectionWrapper,
    LoadingSpinner,
    CheckCircleIcon
  },
  emits: ['updateData', 'checkIsUnsaved'],
  setup(_, { emit }) {
    const store = useStore();

    const route = useRoute();
    const router = useRouter();

    const selected = ref(store.state.product.product.cancellationPolicy);
    const nextCreateStep = computed(() => store.getters["product/getNextStepCreate"]);

    const isTouched = ref(false);

    const isCreateProductMode = computed(() => store.state.product.mode === 'create');

    const { result, loading, onResult } = useQuery(GET_CANCELLATION_POLICY, null, {
      fetchPolicy: 'network-only'
    });

    const policies = computed(() => result.value?.getCancellationPolicy);

    onResult(result => {
      const selectedPolicy = store.state.product.product.cancellationPolicy;
      selected.value = selectedPolicy ?
        result.data.getCancellationPolicy.find(item => item.title.toUpperCase() === selectedPolicy.toUpperCase()) :
        result.data.getCancellationPolicy[0];
    });

    const isPolicyEditable = ref(true);
    const toggleEditPolicy = () => {
      isPolicyEditable.value = !isPolicyEditable.value
    };

    watch(selected, value => {
      emit('updateData', 'cancellationPolicy', value.title);
    });

    watch(isTouched, value => {
      emit('checkIsUnsaved', {
        title: 'Cancellation policy'
      }, value);
    })

    const setTouch = () => {
      isTouched.value = true;
    }

    const { mutate: updateCancellation, loading: updateLoading, onDone } = useMutation(UPDATE_PRODUCT,() => ({
      variables: {
        id: +route.params.id,
        data: {
          cancellation_policy: selected.value.title
        }
      }
    }));

    onDone(() => {
      isTouched.value = false;
      isPolicyEditable.value = false;
    })

    const saveCancellation = () => {
      if (isCreateProductMode.value) {
        store.commit('product/setData', {
          field: 'cancellationPolicy',
          skip: false,
          step: 'cancellationPolicy',
          data: selected.value.title
        });
        isTouched.value = false;
        isPolicyEditable.value = false;
        router.push(nextCreateStep.value);
      } else {
        updateCancellation();
      }
    }

    return {
      selected,
      isPolicyEditable,
      toggleEditPolicy,
      loading,
      policies,
      updateLoading,
      saveCancellation,
      isTouched,
      setTouch,
      isCreateProductMode
    };
  }
};
</script>

<style scoped></style>
