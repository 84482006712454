import gql from "graphql-tag";

const GET_CANCELLATION_POLICY = gql`
 query getCancellationPolicy {
   getCancellationPolicy {
     desc
     title
   }
 }
`;

export {
  GET_CANCELLATION_POLICY
}
